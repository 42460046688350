import 'intersection-observer';

import ClipboardJS from 'clipboard';

/* Load fallback date picker if the browser doesn't have one */
import './datepicker';

import Header from './components/header';
import Input from './components/input';
import FileInput from './components/file-input';
import Amount from './components/amount';
import Foldable from './components/foldable';
import v2Foldable from './components/v2-foldable';
import NavMenu from './components/nav-menu';
import OverlappedImages from './components/overlapped-images';
import CartAddMore from './components/cart-add-more';
import RadioSet from './components/radio-set';
import CartWarning from './components/cart-warning';
import SubmitLock from './components/submit-lock';
import FormValidation from './components/form-validation';
import FieldValidation from './components/field-validation';
import FieldMirrorValue from './components/field-mirror-value';
import PersonalNumber from './components/personal-number';
import BankIDAccounts from './components/bankid-accounts';
import BankIDSubmit from './components/bankid-submit';
import SwishSubmit from './components/swish-submit';
import FrontendSubmit from './components/frontend-submit';
import GiftCardForm from './components/gift-card-form';
import Quantity from './components/quantity';
import ShareLink from './components/share-link';
import LazyLoadImage from './components/lazy-load-image';
import BusinessPackages from './components/business-packages';
import RecommendMessage from './components/recommend-message';
import LinkTerms from './components/link-terms';
import LoadPosts from './components/load-posts';
import MultiplyValues from './components/multiply-values';
import PieChart from './components/pie-chart';
import Numbers from './components/numbers';
import Video from './components/video';
import Script from './components/script';
import Steps from './components/steps';
import ProductPreview from './components/product-preview';
import CampaignNavigation from './components/campaign-navigation';
import CTA from './components/cta';
import Tooltip from './components/tooltip';
import ScrollableList from './components/scrollable-list';
import FormConditions from './components/form-conditions';
import './components/popup';

const makeInstances = (selector, Class) => {
	const elements = [...document.querySelectorAll(selector)];
	return elements.map((element) => new Class(element));
};

window.addEventListener('DOMContentLoaded', () => {
	[
		['[data-header]', Header],
		['.input', Input],
		['.file-input', FileInput],
		['.amount', Amount],
		['.foldable', Foldable],
		['[data-foldable]', v2Foldable],
		['[data-nav-menu]', NavMenu],
		['[data-cart-add-more]', CartAddMore],
		['[data-gift-card-form]', GiftCardForm],
		['[data-radio-set]', RadioSet],
		['[data-cart-warning]', CartWarning],
		['form[data-submit-lock]', SubmitLock],
		['form[data-validate]', FormValidation],
		[
			'input[data-validate], textarea[data-validate], select[data-validate]',
			FieldValidation,
		],
		['[data-mirror-value]', FieldMirrorValue],
		['[data-personal-number]', PersonalNumber],
		['[data-bankid-accounts-form]', BankIDAccounts],
		['[data-bankid-submit]', BankIDSubmit],
		['[data-swish-submit]', SwishSubmit],
		['[data-frontend-submit]', FrontendSubmit],
		['[data-quantity]', Quantity],
		['[data-share-link]', ShareLink],
		['[data-lazy-load]', LazyLoadImage],
		['[data-business-packages]', BusinessPackages],
		['[data-recommend-input]', RecommendMessage],
		['[data-link-terms]', LinkTerms],
		['[data-load-posts]', LoadPosts],
		['[data-multiply-values]', MultiplyValues],
		['[data-product-preview]', ProductPreview],
		['[data-steps]', Steps],
		['[data-campaign-header]', CampaignNavigation],
		['[data-cta]', CTA],
		['[data-tooltip]', Tooltip],
		['[data-scrollable-list]', ScrollableList],
		['form[data-form-conditions]', FormConditions],
	].forEach((args) => makeInstances.apply(null, args));

	new ClipboardJS('[data-clipboard]');

	[...document.querySelectorAll('[data-hide-before-load]')].forEach((el) => {
		el.removeAttribute('data-hide-before-load');
	});
});

window.addEventListener('keydown', (ev) => {
	if (ev.key === 'Tab') {
		document.documentElement.classList.add('tabbed');
	}
});

window.addEventListener('mousedown', () => {
	document.documentElement.classList.remove('tabbed');
});
